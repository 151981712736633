import logo from './logo.svg';
import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Placeholder from './assets/images/presents/placeholder.jpg';
import Schale from './assets/images/presents/schale.webp';
import Tasse from './assets/images/presents/tasse.webp';
import Vase from './assets/images/presents/vase.webp';
import ChristmasCard from './assets/images/presents/christmas_card.webp';
import ShopppenOct from './assets/images/presents/shoppen_oct.jpg';
import Farbe from './assets/images/presents/farbe.webp';
import SchaleWeiss from './assets/images/presents/schale_weiss.jpeg';
import TasseBemalen from './assets/images/presents/tasse_bemalen.jpg';

import Card1 from './assets/images/card/card_1.jpg';
import Card2 from './assets/images/card/card_2.jpg';
import Card3 from './assets/images/card/card_3.jpg';
import Card4 from './assets/images/card/card_4.jpg';

import './App.css';

const MONTHS = [
  'August', 'September', 'October', 'November', 'December',
  'January', 'February', 'March', 'April', 'May', 'June',
  'July'
];

const PRESENTS = [
  // August
  {
    title: "Eine Müslischale",
    image: Schale,
  },
  // September
  {
    title: "Eine Vase & Blume",
    image: Vase,
  },
  // October
  {
    title: "Shopping Tour mit Snacks & Porzellan",
    image: ShopppenOct,
  },
  // November
  {
    title: "Porzellan-Farbe",
    image: Farbe,
  },
  // December
  {
    title: "Revealed on Christmas",
    image: ChristmasCard,
  },
  // January
  {
    title: "Eine Tasse",
    image: Tasse,
  },
  // February
  {
    title: "Fizzen Trip (20 CHF)",
    image: Placeholder,
  },
  // March
  {
    title: "Schale zum Bemalen",
    image: SchaleWeiss,
  },
  // April
  {
    title: "Tasse zum Bemalen",
    image: TasseBemalen,
  },
  // May
  {
    title: "Fizzen Trip (20 CHF)",
    image: Placeholder,
  },
  // June
  {
    title: "Fizzen Trip (20 CHF)",
    image: Placeholder,
  },
  // July
  {
    title: "Fizzen Trip July (20 CHF)",
    image: Placeholder,
  },
];

function PresentTable() {
  const isMonthUnlocked = (monthIndex) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    const monthIndexMapped = (monthIndex + 7) % 12;

    if (currentYear > 2025) {
      return true;
    }
    if (currentYear == 2024) {
      if (monthIndexMapped < 7) {
        return false;
      }
      if (currentMonth <= monthIndexMapped) {
        return false;
      } else {
        return true;
      }
    }
    if (currentYear == 2025) {
      if (monthIndex < 7) {
        return true;
      }
      if (currentMonth <= monthIndexMapped) {
        return false;
      } else {
        return true;
      }
    }
    console.error('Invalid year');
    return false;
  };
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="text-left">Month</th>
          <th className="text-left">Present</th>
          <th className="text-left"></th>
        </tr>
      </thead>
      <tbody>
        {MONTHS.map((month, index) => (
          isMonthUnlocked(index) ? <> <tr key={month}>
            <td>{month}</td>
            <td>{PRESENTS[index].title}</td>
            <td><img src={PRESENTS[index].image} alt={month} className="w-20 h-10 object-contain" /></td>
          </tr></> : null
        ))}
      </tbody>
    </table>
  );
}

const shuffle = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

const FortuneWheel = () => {
  const currentMonth = new Date().getMonth();
  const presentIndex = (currentMonth - 7 + 12) % 12;
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);
  const [selectedPresent, setSelectedPresent] = useState(null);
  const [showWheel, setShowWheel] = useState(true);
  const wheelRef = useRef(null);
  const [presentsShuffled, setPresentsShuffled] = useState(shuffle(PRESENTS));

  const spinWheel = () => {
    if (isSpinning) return;

    setIsSpinning(true);
    setShowWheel(true);
    setSelectedPresent(null);
    const initialSpeed = 50; // degrees per frame
    const duration = 2500; // 5 seconds
    const slowdownRate = 0.98; // Adjust for desired slowdown effect
    const minSpeed = 10;

    let currentSpeed = initialSpeed;
    let currentRotation = rotation;
    let startTime = null;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;

      currentRotation += currentSpeed;
      currentSpeed *= slowdownRate;
      currentSpeed = Math.max(currentSpeed, minSpeed);

      setRotation(currentRotation % 360);

      if (elapsedTime < duration) {
        requestAnimationFrame(animate);
      } else {
        setIsSpinning(false);
        selectWinningPresent();
      }
    };

    requestAnimationFrame(animate);
  };

  const selectWinningPresent = () => {
    setSelectedPresent(PRESENTS[presentIndex]);
    setTimeout(() => setShowWheel(false), 1000); // Delay to allow the wheel to stop visibly
  };

  useEffect(() => {
    spinWheel();
  }, []);

  useEffect(() => {
    const wheel = wheelRef.current;
    if (wheel) {
      wheel.style.transform = `rotate(${rotation}deg)`;
    }
  }, [rotation]);

  return (
    <div className="relative w-80 h-80">
      {showWheel ? (
        <div
          ref={wheelRef}
          className="absolute inset-0 rounded-full overflow-hidden transition-transform duration-100 ease-linear"
        >
          {presentsShuffled.map((present, index) => {
            const angle = (index / PRESENTS.length) * 360;
            const rotateAngle = angle + 90; // Offset to position images correctly
            return (
              <div
                key={index}
                className="absolute w-full h-full"
                style={{
                  transform: `rotate(${rotateAngle}deg)`,
                  transformOrigin: '50% 50%',
                }}
              >
                <div
                  className="absolute top-0 left-1/2 w-1/2 h-1/2 origin-bottom-left"
                  style={{
                    transform: 'translateX(-50%) rotate(-45deg) skew(15deg, 15deg)',
                  }}
                >
                  <img
                    src={Placeholder}
                    alt="Hi there"
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="absolute inset-0 flex flex-col items-center justify-center" style={{ transform: 'none' }}>
          <img
            src={selectedPresent?.image}
            alt={selectedPresent?.title}
            className="max-w-full max-h-full object-contain animate-fade-in"
          />
          <p className="mt-4 text-center font-bold animate-fade-in">{selectedPresent?.title}</p>
        </div>
      )}
    </div>
  );
};

function App() {
  const [color, setColor] = useState('#ff00ff');

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [direction, setDirection] = useState('');
  const [isSpinning, setIsSpinning] = useState(false);

  const images = [
    Card1,
    Card2,
    Card3,
    Card4,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
      setColor(randomColor);
    }, 300);

    return () => clearInterval(interval);
  }, [color]);

  const handleNavigation = (newDirection) => {
    setDirection(newDirection);
    setTimeout(() => {
      if (newDirection === 'next') {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      } else {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      }
      setDirection('');
    }, 500); // Half the duration of the CSS transition
  };

  const imageStyle = {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    position: 'absolute',
  };

  return (
    <div className="min-h-screen bg-fuchsia-700 text-white p-8">
      <h1 className="text-6xl font-bold mb-8 text-center" style={{
        fontFamily: "'Playwrite CU', cursive",
        color: color,
        textShadow: '3px 3px 0px #ff00ff',
      }}>
        Janne's Pretty Porcelain Present!
      </h1>
      <div className="max-w-5xl mx-auto bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl mb-4 text-yellow-300 text-center" style={{
          fontFamily: "'VT323', monospace",
        }}>
          Well, well, well, who do we have here?
        </h2>
        <p className="text-lg mb-4" style={{
          fontFamily: "'Courier Prime', monospace",
        }}>
          Liebstes Janne,<br />
          Ich hoffe du hast bis jetzt noch kein Durchfall in Indonesien bekommen und bist immernoch zufrieden mit deiner Begleitung :-) <br />
          Jeden Monat bis zu deinem nächsten Geburtstag gibt es ein kleines Porcelain Present für dich. <br />
          Manchmal ist es ein Gutschein und manchmal hab ich etwas für dich rausgesucht. <br />
          Du kannst jeden Monat ein neues Present öffnen und dich überraschen lassen. <br />
        </p>
        <div className="h-8"></div>
        <div>
          <PresentTable />
        </div>
        <div className="h-8"></div>

        <div className='flex justify-center items-center h-full'>
          {
            isSpinning ? (
              <FortuneWheel />
            ) : null
          }
        </div>
        <div className="h-8"></div>
        <div className="text-center">
          <button className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded" style={{
            fontFamily: "'Playwrite CU', cursive",
          }} onClick={() => setIsSpinning(true)}>
            ♥ Unlock Monthly Present ♥
          </button>
        </div>
      </div>

      <div className="h-8"></div>
      <div className="max-w-2xl mx-auto bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-3xl mb-4 text-yellow-300 text-center" style={{
          fontFamily: "'VT323', monospace",
        }}>
          Your Birthday Card
        </h2>
        <p className="text-lg mb-4" style={{
          fontFamily: "'Courier Prime', monospace",
        }}>
          Natürlich wirst du die Karte auch noch analog bekommen. <br />
        </p>
        <div className="mb-4 flex justify-center items-center overflow-hidden relative w-full h-[800px]">
          <img
            key={currentImageIndex}
            src={images[currentImageIndex]}
            alt={`Birthday Image ${currentImageIndex + 1}`}
            className={`absolute rounded-lg shadow-md transition-transform duration-500 ${direction === 'next' ? '-translate-x-full' :
              direction === 'back' ? 'translate-x-full' : 'translate-x-0'
              }`}
            style={imageStyle}
          />
          <img
            key={(currentImageIndex + 1) % images.length}
            src={images[(currentImageIndex + 1) % images.length]}
            alt={`Next Birthday Image`}
            className={`absolute rounded-lg shadow-md transition-transform duration-500 ${direction === 'next' ? 'translate-x-0' : 'translate-x-full'
              }`}
            style={imageStyle}
          />
        </div>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => handleNavigation('back')}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center"
            style={{
              fontFamily: "'Playwrite CU', cursive",
            }}
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={() => handleNavigation('next')}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center"
            style={{
              fontFamily: "'Playwrite CU', cursive",
            }}
          >
            <ChevronRight size={24} />
          </button>
        </div>
      </div>

    </div >
  );
}

export default App;
